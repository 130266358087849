<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >

    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="12" md="12">
          <h1 class="page-title">{{ $tc("resources.title") }}</h1>
        </v-col>
      </v-row>
      <span class="ml-10 mt-10 mb-5 font-weight-bold">{{
        $tc("resources.user_manual")
      }}</span>

      <v-row v-if="loading" class="ml-10 mr-10 manual-card">
        <v-col cols="12" sm="1" md="1">
          <v-skeleton-loader type="table-heading"></v-skeleton-loader>
        </v-col>

        <v-col cols="12" sm="9" md="9">
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-skeleton-loader type="card-heading"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row
        v-else
        v-for="(item, index) of manuals"
        :key="index"
        class="ml-10 mr-10 manual-card"
      >
        <v-col cols="12" sm="1" md="1">
          <v-icon class="icon">
            {{ item.icon }}
          </v-icon>
        </v-col>
        <v-col cols="12" sm="9" md="9">
          <div>
            <p class="ma-0">
              <span>
                <p class="ma-0 pa-0 font-weight-bold">{{ item.name }}</p>
                {{ $tc("resources.version") }} {{ item.version }}</span
              >
              <small>
                ({{ item.size }}
                mb)
              </small>
            </p>
            <small class="ma-0">
              {{ $tc("resources.uploaded_at") }}
              {{ item.createdAt | filterDate }}
            </small>
          </div>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-btn block color="buttons" :loading="loading">
            <v-icon left> fas fa-download </v-icon>
            <a
              style="color: white; text-decoration-line: none"
              :href="item.url"
              :download="`${item.name}.pdf`"
              target="_blank"
            >
              {{ $tc("button.download") }}
            </a>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "Resources",
  data() {
    return {
      manuals: [],
      loading: true,
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },
  computed: {
    ...mapState(["group"]),
  },
  methods: {
    ...mapActions(["hide_search"]),
  },
  beforeDestroy() {
    this.hide_search(false);
  },
  mounted() {
    this.hide_search(true);

    db.collection("settings")
      .doc("global")
      .get()
      .then((response) => {
        this.manuals = response.data().userManuals;

        if (this.group.userManual && this.group.userManual.length) {
          this.manuals = [
            ...response.data().userManuals,
            ...this.group.userManual,
          ];
        }

        this.loading = false;
      });
  },
};
</script>

<style scoped>
.manual-card {
  align-items: center;
  background-color: #eaeaea;
  padding: 15px;
  border-radius: 5px;
  margin: 15px;
}

.icon {
  font-size: 50px !important;
}
</style>
